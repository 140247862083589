<template>
  <section class="section section--home">
    <LadHomeSlider cache-prefix="home-slider" :cache-key="cacheKey" />

    <LadHomeSearch cache-prefix="home-search" :cache-key="cacheKey" />

    <LadHomeProviders cache-prefix="home-providers" :cache-key="cacheKey" />

    <LadExperiencesTop cache-prefix="home-ex-top" :cache-key="cacheKey" />

    <LadExperiencesLatest cache-prefix="home-ex-latest" :cache-key="cacheKey" />

    <LadHomeLocations cache-prefix="home-locations" :cache-key="cacheKey" />

    <LadHomeDestinations
      cache-prefix="home-destinations"
      :cache-key="cacheKey"
    />

    <LadHomeCategories cache-prefix="home-categories" :cache-key="cacheKey" />

    <LadHomeVisasAndRestrictions
      cache-prefix="home-providers"
      :cache-key="cacheKey"
    />

    <LadHomeBlog cache-prefix="home-blog" :cache-key="cacheKey" />

    <LazyLoading
      :key="`newsletters`"
      :loaded="lazy.newsletters"
      @loaded="(e) => (lazy.newsletters = e)"
    >
      <LadHomeNewsletters v-if="lazy.newsletters" />
    </LazyLoading>

    <LadConfidence cache-prefix="lad-confidence" :cache-key="cacheKey" />

    <LadReviewsDialog />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { hydrateNever, hydrateWhenVisible } from 'vue-lazy-hydration'
import StaticMetaMixin from '@/utils/mixin/static-meta'
import LadReviewsDialog from '@/components/reviews/dialog'

export default {
  name: 'LadHome',
  components: {
    LadReviewsDialog,
    LadHomeSlider: hydrateNever(() =>
      import(
        /* webpackChunkName: "lad-home-slider" */ '@/components/cover/home'
      )
    ),
    LadHomeSearch: () =>
      import(
        /* webpackChunkName: "lad-cover-slider" */ '@/components/pages/home/partials/search'
      ),

    LadHomeVisasAndRestrictions: hydrateWhenVisible(() =>
      import(
        /* webpackChunkName: "lad-home-visas-and-restrictions" */ '@/components/pages/home/partials/visas-and-restrictions'
      )
    ),

    LadHomeProviders: () =>
      import(
        /* webpackChunkName: "lad-home-providers" */ '@/components/pages/home/partials/providers'
      ),
    LadExperiencesTop: () =>
      import(
        /* webpackChunkName: "lad-home-top-experiences" */ '@/components/experiences/top'
      ),
    LadExperiencesLatest: () =>
      import(
        /* webpackChunkName: "lad-home-latest-experiences" */ '@/components/experiences/latest'
      ),

    LadHomeLocations: hydrateWhenVisible(() =>
      import(
        /* webpackChunkName: "lad-home-locations" */ '@/components/pages/home/partials/locations'
      )
    ),

    LadHomeCategories: hydrateWhenVisible(() =>
      import(
        /* webpackChunkName: "lad-home-categories" */ '@/components/pages/home/partials/categories'
      )
    ),

    LadHomeBlog: hydrateWhenVisible(() =>
      import(
        /* webpackChunkName: "lad-home-blog" */ '@/components/pages/home/partials/blog'
      )
    ),

    LadHomeNewsletters: () =>
      import(
        /* webpackChunkName: "lad-home-newsletters" */ '@/components/pages/home/partials/newsletters'
      ),
    LadConfidence: () =>
      import(
        /* webpackChunkName: "lad-confidence" */ '@/components/confidence'
      ),

    LadHomeDestinations: hydrateWhenVisible(() =>
      import(
        /* webpackChunkName: "lad-home-destinations" */ '@/components/pages/home/partials/destinations/index'
      )
    ),
  },
  data() {
    return {
      base: 'home',
      redirect: undefined,
      sendingReview: false,
      lazy: {
        newsletters: false,
      },
      cover: { sources: [], medias: [], src: null, srcset: null },
    }
  },
  computed: {
    ...mapGetters('settings', ['language']),
    ...mapGetters('device', ['isMobile']),
    cacheKey() {
      const name = this.$route.path.replace(/\//g, '-').replace('-', '')

      return `${name}-${this.language}--${this.isMobile ? 'm' : 'd'}`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.redirect = route.query && route.query.redirect

        if (this.isAuth && this.redirect) {
          this.$router.push(this.redirect)
        }

        if (process.client && route.hash) {
          setTimeout(() => this.$scrollTo(window.location.hash), 200)
        }

        switch (route.query.source) {
          case '401':
            this.$message({
              message: this.$t('general.session_expired'),
              type: 'error',
              duration: 8000,
            })

            /** redireccionando para quitar el source(es el home siempre) */
            this.$router.push({ name: route.name })
            break
          default:
            break
        }
      },
    },
  },
  async beforeMount() {
    /** seteando los valores por defecto */
    await this.$store.dispatch('search/setForm', {
      filters: {
        age: [],
        category: [],
        date: [],
        duration: [],
        price: { min: null, max: null, type: 'price' },
      },
    })
  },
  async mounted() {
    if (!this.isAuth) {
      if (this.$route.query.redirect) {
        await this.$store.dispatch('modals/toggle', 'auth')
      } else if (this.$route.query.user) {
        const type = this.$route.query.user

        if (['login', 'recovery'].includes(type)) {
          await this.$store.dispatch('modals/toggle', {
            open: 'auth',
            data: { type },
          })
        } else if (type === 'reset' && this.$route.query.token) {
          await this.$store.dispatch('modals/toggle', {
            open: 'auth',
            data: { type },
          })
        }
      }
    }
  },
  created() {
    this.$nuxt.$on('set-preload', (response) => {
      this.cover = response
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('set-preload')
  },
  head() {
    const staticMeta = StaticMetaMixin.head.call(this)

    const preloads = []
    this.cover.sources.forEach((source, i) => {
      preloads.push({
        rel: 'preload',
        as: 'image',
        fetchpriority: 'high',
        imagesrcset: source,
        media: this.cover.medias[i].media,
      })
    })

    return {
      ...staticMeta,
      link: [].concat(staticMeta.link, preloads),
    }
  },
}
</script>

<style lang="scss">
.section--home {
  flex: 1;
  width: 100%;

  .home-wrapper--hero-cover {
    position: relative;
    z-index: 5;

    & + .home-wrapper--search-autocomplete {
      position: relative;
      z-index: 10;
    }
  }

  .wrapper-section-header {
    padding: 15px 0;
  }

  .home-section-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .home-section-description {
    text-align: center;
    text-transform: uppercase;
  }

  .lad-confidence {
    .sprite-icons {
      width: 115px;
      height: 115px;
    }
  }
}
</style>
