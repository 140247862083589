<template>
  <LadDialog
    v-if="sending"
    width="30%"
    :visible="dialog.show"
    class="lad-reviews-dialog"
    :handle-before-close="handleClose"
  >
    <template slot="dialog-body">
      <el-row type="flex">
        <el-col :span="24">
          <h2 class="lad-reviews-dialog--title">{{ dialog.title }}</h2>
        </el-col>
        <el-col :span="24">
          <div v-if="dialog.type === 'loading'" v-loading="true"></div>
          <svg-icon :name="dialog.image" class="lad-reviews-dialog--image" />
        </el-col>
        <el-col :span="24">
          <p class="lad-reviews-dialog--message">{{ dialog.message }}</p>
        </el-col>
        <el-col v-if="editing" :span="24">
          <el-form
            ref="reviewForm"
            :model="form"
            :rules="rules"
            label-position="top"
          >
            <el-form-item :label="$t(`${base}.form.labels.score`)" prop="score">
              <el-rate v-model="form.score" aria-label="reviews" />
            </el-form-item>
            <el-form-item
              :label="$t(`${base}.form.labels.message`)"
              prop="message"
            >
              <el-input
                v-model="form.message"
                type="textarea"
                :rows="4"
                :maxlength="1200"
                :placeholder="$t(`${base}.form.placeholders.message`)"
              />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </template>

    <template v-if="editing" slot="dialog-footer">
      <el-button type="primary" @click="handleValidate">{{
        $t('general.send')
      }}</el-button>
    </template>
  </LadDialog>
</template>

<script>
import { hasOwnProperties } from '@/utils'

export default {
  name: 'LadReviewsDialog',
  components: {
    LadDialog: () =>
      import(
        /* webpackChunkName: "lad-dialog" */ '@/components/frontends/Modal/index'
      ),
  },
  data() {
    const base = 'components.reviews.dialog'

    /** validando campo obligatorio */
    const requiredField = (rule, value, callback) => {
      let error
      const field = rule.field
      const label = field.split('.').reverse()

      if (value === undefined || value === null || value.length === 0) {
        const name = this.$t(`${base}.form.labels.${label}`)

        error = new Error(
          this.$t(`general.validations.required_input`, { name })
        )
      }

      callback(error)
    }

    /** adiciona mensajes de validacion del servidor */
    const validateServer = (rule, value, callback) => {
      let error
      const field = rule.field
      const label = field.split('.').reverse()

      if (this.serverErrors[field]) {
        const name = this.$t(`${base}.form.labels.${label}`)

        error = new Error(this.serverErrors[field][0].replace(field, name))
      }

      callback(error)
    }

    return {
      base,
      sending: false,
      dialog: {
        show: true,
        type: 'loading',
        image: '',
        title: this.$t(`${base}.loading.title`),
        message: this.$t(`${base}.loading.message`),
      },
      form: {
        score: null,
        message: null,
        user: {
          email: null,
        },
        reservation: {
          code: null,
        },
      },
      rules: {
        score: [
          {
            required: true,
            trigger: 'change',
            validator: requiredField,
          },
          { trigger: 'blur', validator: validateServer },
        ],
        message: [
          {
            required: true,
            trigger: 'change',
            validator: requiredField,
          },
          { trigger: 'blur', validator: validateServer },
        ],
      },
      serverErrors: {},
    }
  },
  computed: {
    editing() {
      return (
        this.dialog.type !== 'loading' && Object.keys(this.serverErrors).length
      )
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (
        (this.$route.query.score || this.$route.query.review_score) &&
        (this.$route.query.message || this.$route.query.review_content) &&
        (this.$route.query.user_email || this.$route.query.email) &&
        this.$route.query.reservation_code
      ) {
        this.initForm()
      }
    },
    initForm() {
      const query = this.$route.query

      this.form = {
        score: parseInt(query.score || query.review_score),
        message: query.message || query.review_content,
        user: {
          email: query.user_email || query.email,
        },
        reservation: {
          code: query.reservation_code,
        },
      }

      this.LadNextTick(() => {
        this.$router.push({ params: {} })
        this.sending = true
        this.handleSubmit()
      }, 'LadReviewsDialog')
    },
    handleValidate() {
      this.serverErrors = {}

      this.$refs.reviewForm.validate((valid) => {
        if (valid) {
          this.setDialogMessage('loading')

          setTimeout(() => {
            this.handleSubmit()
          }, 1000)
        }
      })
    },
    setDialogMessage(type) {
      this.dialog.type = type
      this.dialog.title = this.$t(`${this.base}.${type}.title`)
      this.dialog.message = this.$t(`${this.base}.${type}.message`)

      switch (type) {
        case 'success':
          this.dialog.image = 'review/local-adventures-sent'
          break
        case 'error':
          this.dialog.image = 'review/local-adventures-already-sent'
          break
        case 'warning':
          this.dialog.image = 'review/local-adventures-not-sent'
          break
        default:
          this.dialog.image = ''
          break
      }
    },
    async handleSubmit() {
      try {
        const { status } = await this.$axios.post(
          `/api/v1.5/orders/store/${this.form.reservation.code}/review`,
          this.form
        )

        if (status === 'success') {
          this.setDialogMessage('success')
        } else {
          this.setDialogMessage('warning')
        }

        setTimeout(() => this.handleClose(), 4000)
      } catch (error) {
        this.setDialogMessage('error')

        if (hasOwnProperties(error, 'response.data')) {
          const eData = error.response.data

          if (hasOwnProperties(eData, 'validation_errors')) {
            this.serverErrors = eData.validation_errors

            if (typeof this.$refs.reviewForm !== 'undefined') {
              await this.$refs.reviewForm.validate(() => {})
            }
          } else {
            setTimeout(() => this.handleClose(), 5000)
          }
        }
      }
    },
    handleClose() {
      this.dialog.show = false

      setTimeout(() => {
        this.sending = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
.lad-reviews-dialog {
  .el-dialog__body-wrapper {
    flex-direction: column;

    .el-row {
      flex-wrap: wrap;
    }

    & > div:nth-child(2) {
      display: flex;
      flex: 0.8;
      align-items: center;
    }
  }

  .el-dialog__footer-wrapper {
    padding: 0;
    margin: 0;

    .el-button {
      margin: 20px 25px;
    }
  }

  .el-loading-parent--relative {
    width: 100%;
    padding: 40px 0 20px;
  }

  &--title {
    text-align: center;
    font-size: 2em;
  }

  &--message {
    font-size: 1.1em;
    padding: 20px 0;
  }

  &--image {
    &.svg-icon {
      width: 100%;
      height: auto;
      max-height: 300px;
      padding: 10px 0;
    }
  }

  @media screen and (min-width: 451px) {
    .el-dialog {
      margin-top: 5vh !important;
    }

    .el-rate__icon {
      font-size: 25px;
    }
  }

  @media screen and (max-width: 450px) {
    &--image {
      &.svg-icon {
        max-height: 200px;
      }
    }
  }
}
</style>
